import qs from 'query-string';
import Axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { environment } from 'shared/environment';
import { productMethods, ApiProductMethods } from './methods/product';
import { objectMethods, ApiObjectMethods } from './methods/object';
import { authMethods, ApiAuthMethods } from './methods/auth';
import { userMethods, ApiUserMethods } from './methods/user';
import { ApiOrderMethods, orderMethods } from './methods/order';
import { ApiEventsMethods, eventsMethods } from './methods/events';
import { ApiFilesMethods, filesMethods } from './methods/files';
import { ApiAdminMethods, adminMethods } from './methods/admin';
import { ApiMeasurementMethods, measurementMethods } from './methods/measurement';
import { ApiOtherMethods, otherMethods } from './methods/other';
import { ApiEstimateMethods, estimateMethods } from './methods/estimate';
import { ApiUploadMethods, uploadMethods } from './methods/upload';
import { ApiRiskMethods, riskMethods } from './methods/risk';
import { ApiResetsMethods, resetsMethods } from './methods/resets';
import { ApiTokenMethods, apiTokenMethods } from './methods/api-token';
import { ApiTransactionMethods, apiTransactionMethods } from './methods/transaction';
import { ApiPartnerMethods, partnerMethods } from './methods/partner';
import { ApiSettingsMethods, settingsMethods } from './methods/settings';

type ResponseMapFunc = (data: AxiosResponse<ApiRawResponse>) => any;

const defaultApiResponse: ResponseMapFunc = (response: AxiosResponse) => response.data;

export class RealtyCloudApi {
  public products: ApiProductMethods = productMethods.call(this);

  public object: ApiObjectMethods = objectMethods.call(this);

  public auth: ApiAuthMethods = authMethods.call(this);

  public user: ApiUserMethods = userMethods.call(this);

  public order: ApiOrderMethods = orderMethods.call(this);

  public admin: ApiAdminMethods = adminMethods.call(this);

  public partner: ApiPartnerMethods = partnerMethods.call(this);

  public events: ApiEventsMethods = eventsMethods.call(this);

  public files: ApiFilesMethods = filesMethods.call(this);

  public other: ApiOtherMethods = otherMethods.call(this);

  public estimate: ApiEstimateMethods = estimateMethods.call(this);

  public measurement: ApiMeasurementMethods = measurementMethods.call(this);

  public upload: ApiUploadMethods = uploadMethods.call(this);

  public risk: ApiRiskMethods = riskMethods.call(this);

  public reset: ApiResetsMethods = resetsMethods.call(this);

  public transaction: ApiTransactionMethods = apiTransactionMethods.call(this);

  public apiToken: ApiTokenMethods = apiTokenMethods.call(this);

  public settings: ApiSettingsMethods = settingsMethods.call(this);

  private _sourceSite: string;

  public _axios: AxiosInstance;

  private _token!: string;

  private api: AxiosInstance;

  public raw: any;

  private responseDataMapper: ResponseMapFunc = defaultApiResponse;

  constructor(responseDataMapper?: ResponseMapFunc) {
    if (responseDataMapper) {
      this.responseDataMapper = responseDataMapper;
    }

    this.api = Axios.create({
      baseURL: environment.apiUrl,
      paramsSerializer: this.paramsSerializer,
    });

    this._axios = Axios;

    this.initInterceptors();
  }

  public request(config: AxiosRequestConfig) {
    const request = this.api.request(config);

    return request.then(this.responseDataMapper);
  }

  public setToken(token: string) {
    this._token = token;
  }

  private paramsSerializer(params: { [key: string]: any }): string {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key];

        if (typeof value === 'string' && !value) {
          params[key] = undefined;
        }
      }
    }

    return qs.stringify(params);
  }

  private initInterceptors() {
    this.api.interceptors.request.use(
      (config) => {
        if (this._token) {
          config.headers.Authorization = `Bearer ${this._token}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  public getSourceSite() {
    return {
      'Source-Site': this._sourceSite,
    };
  }

  public setSourceSite(source: string) {
    this._sourceSite = source;
  }
}
