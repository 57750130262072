import { RealtyCloudApi } from 'shared/api/api';

export interface ApiEstimateMethods {
  Calc<T = EstimateResponse>(params: EstimateRequestBody): Promise<T>;
}

export function estimateMethods(this: RealtyCloudApi) {
  return {
    Calc: (data: EstimateRequestBody) => {
      return this.request({
        url: '/estimate',
        baseURL: 'https://api-test.realtycloud.ru',
        method: 'POST',
        data,
      });
    },
  };
}
