import qs from 'query-string';
import { RealtyCloudApi } from 'shared/api/api';

interface ResetPassData {
  username: string;
}

export interface ApiAuthMethods {
  Register<T>(user: User, campaign?: string): Promise<T>;
  Login<T = User>(credentials: Credentials): Promise<T>;
  CheckToken<T = CheckTokenResponse>(): Promise<T>;
  ResetPassword<T = ResetPasswordResponse>(data: ResetPassData): Promise<T>;
}

export function authMethods(this: RealtyCloudApi): ApiAuthMethods {
  return {
    Register: (user: User, campaign?: string) => {
      const params = { campaign };
      return this.request({
        url: '/register',
        method: 'POST',
        data: user,
        headers: this.getSourceSite(),
        params,
      });
    },

    Login: (credentials: Credentials) => {
      return this.request({
        url: '/login',
        method: 'POST',
        data: credentials,
      });
    },

    CheckToken: () => {
      return this.request({
        url: '/token',
        method: 'POST',
      });
    },

    ResetPassword: (data: ResetPassData) => {
      return this.request({
        url: '/reset',
        method: 'PUT',
        data: qs.stringify(data),
      });
    },
  };
}
