






































import Vue from 'vue';
import { formatter as cadastralFormatter } from 'shared/utils/cadastralNumber';
import AppSearchResultDialog from './SearchResultDialog.vue';

const checkIfNumberRegex = /^(\d+(?:\s*[:|/|-|;|^\s|.]\s*)?){1,}$/;

export default Vue.extend({
  name: 'AppSearch',

  inject: [ 'options' ],

  data() {
    return {
      searchQuery: '',
      isNumber: false,
    };
  },

  mounted() {
    const options: any = (this as any).options;
    if (options.input.style) {
      const input = (this.$refs.container as any).querySelector('input');

      input.setAttribute('style', options.input.style);
    }
  },

  methods: {
    onSubmit() {
      let q = this.searchQuery.trim();

      if (!q) {
        return;
      }

      this.$analytics.send('search_start');

      this.$emit('submit', q);

      // Форматирум кадастровый номер, если пользователь ввёл его с очепятками
      // Функция игнорит обычные адреса
      const formatterResult = cadastralFormatter(q);

      // Если получилось преобразовать, то меняем query на нормальный
      if (formatterResult.success) {
        this.searchQuery = q = formatterResult.result;
      }

      this.$dialog.open({
        component: AppSearchResultDialog,
        props: {
          query: q,
        },
      });
    },

    onFocus() {
      this.$analytics.send('search_focus');
      this.$emit('focus');
      // analytics.ym('reachGoal', );
    },

    // onSelect() {

    // },

    handleSubmit() {
      const isValidAddress = /д.?[0-9A-Za-z]{1,}/.test(this.searchQuery);
      this.isNumber = checkIfNumberRegex.test(this.searchQuery.trim());

      if (this.isNumber || isValidAddress) {
        return this.onSubmit();
      }
    },
  },
});
