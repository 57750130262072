exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Dadata-style_suggestion_3nFcK{position:relative}.Dadata-style_suggestion-input_11glC{position:relative;z-index:150;-webkit-transition:border-color .15s ease-in-out,background .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,background .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,background .15s ease-in-out;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,background .15s ease-in-out,-webkit-box-shadow .15s ease-in-out}.Dadata-style_suggestion-input_11glC:focus{background:#fff}.Dadata-style_suggestion-list-wrap_3W_TH{position:absolute;top:-6px;left:-6px;right:-6px;padding-top:50px;-webkit-box-shadow:0 .125rem .25rem rgba(0,0,0,.075);box-shadow:0 .125rem .25rem rgba(0,0,0,.075);background:#fff;z-index:140;-webkit-transform-origin:center top;transform-origin:center top;overflow:hidden;border-radius:9px;border-top-left-radius:.5625rem;border-top-right-radius:.5625rem}.Dadata-style_suggestion-list-title_avd0Q{color:#6a737d;font-size:.875rem;padding:5px 10px 0}.Dadata-style_suggestion-item_1ISnu{padding:5px 10px}.Dadata-style_suggestion-item_highlight_3wmaH{background:rgba(34,129,249,.06)}.Dadata-style_suggestion-item__mark_19jzj{color:#2281f9;background:transparent;padding:0}@media(max-width:575.98px){.Dadata-style_suggestion-item_1ISnu{font-size:.875rem}}", ""]);

// exports
exports.locals = {
	"suggestion": "Dadata-style_suggestion_3nFcK",
	"suggestion-input": "Dadata-style_suggestion-input_11glC",
	"suggestion-list-wrap": "Dadata-style_suggestion-list-wrap_3W_TH",
	"suggestion-list-title": "Dadata-style_suggestion-list-title_avd0Q",
	"suggestion-item": "Dadata-style_suggestion-item_1ISnu",
	"suggestion-item_highlight": "Dadata-style_suggestion-item_highlight_3wmaH",
	"suggestion-item__mark": "Dadata-style_suggestion-item__mark_19jzj"
};