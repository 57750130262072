import { RealtyCloudApi } from 'shared/api/api';

export interface ApiUserMethods {
  UpdatePassword<T = User>(data: UpdatePasswordData): Promise<T>;
  UpdateInfo<T>(data: User): Promise<T>;
  OrdersList<T = UserOrderResponse[]>(params?: any): Promise<T>;
}

export function userMethods(this: RealtyCloudApi): ApiUserMethods {
  return {
    UpdateInfo: (data: User) => {
      return this.request({
        url: '/person',
        method: 'PUT',
        data,
      });
    },

    UpdatePassword: (data: UpdatePasswordData) => {
      return this.request({
        url: '/person/update_password',
        method: 'PUT',
        data,
      });
    },

    OrdersList: (params?: any) => {
      return this.request({ url: '/orders', params });
    },
  };
}
