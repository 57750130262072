import Vue from 'vue';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';
import './registerComponents';

import '@/object-search/assets/images/risk_example_1.png';
import '@/object-search/assets/images/risk_example_2.png';

import App from './App.vue';
import installAnalyticsPlugin from 'shared/utils/analytics/analytics-plugin';
import { installApiPlugin } from 'shared/api';

Vue.use(installApiPlugin);
Vue.use(installAnalyticsPlugin({}));

// eslint-disable-next-line no-new
const forms = document.querySelectorAll<HTMLDivElement>('[data-realtycloud-form]');

const defaults = merge({}, (window as any).__RC_DEFAULTS__);

forEach(forms, (form: HTMLDivElement) => {
  let options = merge(defaults, {
    hostRedirect: 'https://b2b.realtycloud.ru',

    params: {},

    input: {
      placeholder: 'Введите адрес или кадастровый номер',
    },

    button: {
      text: 'Проверить объект',
    },
  });

  const data = form.getAttribute('data-realtycloud-form');

  try {
    const o = JSON.parse(data);
    options = merge(options, o);
  } catch (error) {
    console.error(error);
  }

  // eslint-disable-next-line no-new
  new Vue({
    el: '[data-realtycloud-form]',
    data: {
      options,
    },

    provide() {
      return {
        options: this.options,
      };
    },

    render: (h) => h(App),
  });
});
