import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

export interface ApiProductMethods {
  List<T = Product[]>(campaign?: string): Promise<T>;
  ActivatePromoCode<T = ActivateCouponResponse>(code: string): Promise<T>;
}

export function productMethods(this: RealtyCloudApi): ApiProductMethods {
  return {
    List: (campaign?: string) => {
      const params: AxiosRequestConfig['params'] = { };

      if (campaign) {
        params.campaign = campaign;
      }

      return this.request({ url: '/products', params });
    },

    ActivatePromoCode: (code: string) => {
      return this.request({
        url: '/coupon',
        method: 'POST',
        data: { code },
      });
    },
  };
}
