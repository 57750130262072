import { RealtyCloudApi } from '../../api';

export interface ApiAdminMethods {
  Users<T = any[]>(params?: AdminUsersParams): Promise<T>;
  Orders<T = AdminOrderInfo[]>(params?: AdminOrdersParams): Promise<T>;
  CreateCoupon<T>(data: CreateCouponData): Promise<T>;
  GetSourceList<T = SourceItem[]>(): Promise<T>;
  GetCoupons<T>(params?: AdminCouponsParams): Promise<T>;
  GetUserInfo<T>(userID: string): Promise<T>;
  GetCampaigns<T>(): Promise<T>;
  ManualUpdate<T>(data: ManualUpdateData): Promise<T>;
  ManualAttachOrder<T>(params: ManualAttachOrderParams): Promise<T>;
  ManualAttachOrderItem<T>(params: ManualAttachOrderItemParams): Promise<T>;
  SearchUsers<T = any[]>(params?: AdminOrdersParams): Promise<T>;
  Stats<T = any>(params: StatsDateParams): Promise<T>;
  TopDonators<T = StatsDonator[]>(params: StatsDateParams): Promise<T>;
  OrderItemsList<T = AdminOrderItem2[]>(params: AdminOrdersParams): Promise<T>;
  StatusesList<T = { statuses: string[] }>(): Promise<T>;
  UpdateOrderItemKadnetStatus<T = any>(body: UpdateKadnetStatusRequestBody): Promise<T>;
  UpdateSpvOrderItemStatus<T = any>(orderItemId: OrderItemID): Promise<T>;
  UpdateOrderItemStatus<T = any>(orderItemId: OrderItemID, status: string): Promise<T>;
  DownloadSpvOrderItem<T = any>(orderItemId: OrderItemID): Promise<T>;
  SendEmailWithAttachment<T = any>(orderItemID: OrderItemID): Promise<T>;
  Emails<T = any>(params: AdminEmailsParams): Promise<T>;
  PartnerStats<T = AdminPartnerStatsInfoResponse>(params: StatsDateParams & PaginationParams): Promise<T>;
}

export function adminMethods(this: RealtyCloudApi): ApiAdminMethods {
  return {
    Users: (params?: AdminUsersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Orders: (params?: AdminOrdersParams) => {
      return this.request({ url: '/admin/orders', params });
    },

    CreateCoupon: (data: CreateCouponData) => {
      return this.request({
        url: '/admin/coupon',
        method: 'POST',
        data,
      });
    },

    GetSourceList: () => {
      return this.request({ url: '/admin/source' });
    },

    GetCoupons: (params?: AdminCouponsParams) => {
      return this.request({ url: '/admin/coupon', params });
    },

    GetCampaigns: () => {
      return this.request({ url: '/admin/campaign' });
    },

    GetUserInfo: (userID: string) => {
      return this.request({ url: `/person/${userID}` });
    },

    ManualUpdate: (data: ManualUpdateData) => {
      return this.request({ url: '/admin/manual_update', method: 'PUT', data });
    },

    ManualAttachOrder: (data: ManualAttachOrderParams) => {
      return this.request({
        url: '/admin/orders/attach_order',
        method: 'POST',
        data,
      });
    },

    ManualAttachOrderItem: (data: ManualAttachOrderItemParams) => {
      return this.request({
        url: '/admin/orders/attach_product',
        method: 'POST',
        data,
      });
    },

    SearchUsers: (params: AdminOrdersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Stats: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };

      return this.request({ url: '/admin/stats', params });
    },

    TopDonators: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };
      return this.request({ url: '/admin/top_donators', params });
    },

    OrderItemsList: (params: AdminOrdersParams) => {
      return this.request({
        url: '/admin/order_item/list',
        params,
      });
    },

    StatusesList: () => {
      return this.request({
        url: '/admin/order_item/all_statuses',
      });
    },

    UpdateOrderItemKadnetStatus: (data) => {
      return this.request({
        url: '/admin/order_item/update_order_item_kadnet_status',
        method: 'PUT',
        data,
      });
    },

    UpdateSpvOrderItemStatus: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/update_order_item_spv_status',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    UpdateOrderItemStatus: (orderItemId: OrderItemID, status: string) => {
      return this.request({
        url: '/admin/order_item/update_order_item_status',
        method: 'PUT',
        data: { order_item_id: orderItemId, status },
      });
    },

    DownloadSpvOrderItem: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/download_order_item_spv',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    SendEmailWithAttachment: (orderItemID: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/send_email_with_attachment',
        params: { orderItemID },
      });
    },

    Emails: (params: AdminEmailsParams) => {
      return this.request({
        url: '/admin/email',
        params,
      });
    },

    PartnerStats: ({ from, to, ...pagination }: StatsDateParams & PaginationParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
        ...pagination,
      };

      return this.request({
        url: '/admin/partner_account_sum',
        params,
      });
    },
  };
}
