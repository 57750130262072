import Vue from 'vue';
import dadataComponent from 'shared/components/ui/Dadata';
import buttonComponent from 'shared/components/ui/Button';
import initDialogPlugin from 'shared/components/ui/Dialog/dialogPlugin';
import loaderComponent from 'shared/components/ui/Loader';
import installPhotoSwipePlugin from 'shared/plugins/photoswipe';
import inputComponent from 'shared/components/ui/Input';

Vue.use(buttonComponent);
Vue.use(dadataComponent);
Vue.use(initDialogPlugin);
Vue.use(loaderComponent);
Vue.use(installPhotoSwipePlugin);
Vue.use(inputComponent);
