










import Vue from 'vue';
import qs from 'qs';
import AppSearchObjectsList from 'shared/components/SearchObjectsList.vue';

export default Vue.extend({
  components: { AppSearchObjectsList },

  inject: ['options'],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  methods: {
    onSelect(object: RealtyObject) {
      this.$dialog.close();

      let objectKey = object.Number;

      if (!objectKey) {
        objectKey = object.Address.replace(/\//g, '%2F');
      }

      const params = qs.stringify((this as any).options.params);

      location.assign(`${(this as any).options.hostRedirect}/object/${objectKey}?${params}`);
    },
  },
});
