import { RealtyCloudApi } from 'shared/api/api';

export interface ApiOtherMethods {
  Poll<T = PollResponse>(data: PollJSONData): Promise<T>;
  OrdersDoneCount<T = OrdersDoneCountResponse>(): Promise<T>;
}

export function otherMethods(this: RealtyCloudApi): ApiOtherMethods {
  return {
    Poll: (data: PollJSONData) => {
      return this.request({
        url: '/poll',
        method: 'POST',
        data,
      });
    },

    OrdersDoneCount: () => {
      return this.request({
        url: '/orders_done_count',
      });
    },
  };
}
