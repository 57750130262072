























































import Vue from 'vue';

import AppSearch from './Search.vue';

export default Vue.extend({
  components: { AppSearch },

  methods: {
    showExample() {
      let prefix = '';

      if (process.env.NODE_ENV === 'production') {
        prefix = 'https://realtycloud.ru/forms/search/';
      }

      this.$photoSwipe([
        {
          src: prefix + 'img/risk_example_1.png',
          w: 1167,
          h: 860,
        },
        {
          src: prefix + 'img/risk_example_2.png',
          w: 1162,
          h: 992,
        },
      ]);
    },
  },
});
