import { RealtyCloudApi } from '../api';

export interface ApiFilesMethods {
  GetFileBlob<T>(downloadUrl: string): Promise<T>;
}

export function filesMethods(this: RealtyCloudApi): ApiFilesMethods {
  return {
    GetFileBlob: (url: string) => {
      return this.request({ url, responseType: 'blob' });
    },
  };
}
