





















import Vue from 'vue';
export default Vue.extend({
  props: {
    value: [ String, Number, Object, Array ],
    items: [ Object, Array ],
    valueKey: String,
    size: String,
  },

  computed: {
    nativeInputValue(): string | number {
      return this.value == null ? '' : this.value;
    },

    itemsType() {
      if (Array.isArray(this.items)) {
        return 'array';
      }

      if (typeof this.items === 'object') {
        return 'object';
      }

      return null;
    },
  },

  methods: {
    onChange(event: Event) {
      const target = event.target as HTMLSelectElement;

      this.$emit('input', target.value);
    },
  },
});
