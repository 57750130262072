const environment = {
  apiUrl: 'https://api-test.realtycloud.ru',
  yandexMapApiKey: 'ff82ecae-0fa5-43d9-8793-9b87787697b7',
  cloudpaymentsApiKey: 'pk_5d2c1524cbcc49a9bb3e883524eca',
};

if (process.env.NODE_ENV === 'production') {
  environment.apiUrl = 'https://api.realtycloud.ru';
  environment.cloudpaymentsApiKey = 'pk_8841645ce8ca3d4c831d682c90ddd';
}

export { environment };
