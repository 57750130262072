import { render, staticRenderFns } from "./Dadata.vue?vue&type=template&id=d7f407a8&scoped=true&"
import script from "./Dadata.vue?vue&type=script&lang=ts&"
export * from "./Dadata.vue?vue&type=script&lang=ts&"
import style0 from "./Dadata.style.scss?vue&type=style&index=0&lang=scss&module=%24dadata&"
import style1 from "./Dadata.vue?vue&type=style&index=1&id=d7f407a8&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$dadata"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "d7f407a8",
  null
  
)

export default component.exports